import { Controller } from "@hotwired/stimulus"
import { init } from 'onfido-sdk-ui';

export default class extends Controller {
  static targets = [ "button" ]
  static values = {
    token: String,
    nationality: String,
    nationalityAlpha3: String,
    phone: String,
    nextstep: String
  }

  connect() {
    if (this.nationalityValue == 'MY') {
        var steps_config = [
            'welcome',
            {
                "type": "document",
                "options": {
                    "documentTypes": {
                        "national_identity_card": {
                            "country": "MYS"
                        },
                    },
                    "forceCrossDevice": true,
                    "useLiveDocumentCapture": true
                },
            },
            {
                "type": "face",
                "options": {
                    "requestedVariant": 'standard',
                },
            },
            'complete'
        ]
     } else {
        var steps_config = [
            'welcome',
            {
                "type": "document",
                "options": {
                    "documentTypes": {
                        "passport": {
                            "country": this.nationalityAlpha3Value
                        }
                    },
                    "forceCrossDevice": true,
                    "useLiveDocumentCapture": true
                },
            },
            {
                "type": "face",
                "options": {
                    "requestedVariant": 'standard',
                },
            },
            'complete'
        ]
     };

    init({
        token: this.tokenValue,
        containerId: 'onfido-mount',
        smsNumberCountryCode: this.nationalityValue,
        userDetails: {
            smsNumber: this.phoneValue
        },
        steps: steps_config,
        onComplete: function (data) {
            console.log(data);
            window.location.href = '/verification/address';
        },

    })
  }

  disconnect() {
  }
}
