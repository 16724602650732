import { Controller } from "@hotwired/stimulus"
import Dropzone from "dropzone";

export default class extends Controller {
  static targets = [ "dropzone" ]

  connect() {
    let myDropzone = new Dropzone("#my-form");
    myDropzone.on("addedfile", file => {
      console.log(`File added: ${file.name}`);
    });
  }

  disconnect() {
  }
}