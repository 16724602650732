import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
    static targets = [ "input" ]
    static values = { hiddenfield: String }

    connect() {
        const input = this.inputTarget;
        this.iti = intlTelInput(input, {
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js",
            hiddenInput: this.hiddenfieldValue, // requires controller to accept 'fullphone' as param
            initialCountry: "my",
            preferredCountries: ["my", "sg", "id"],
            separateDialCode: true,
            autoPlaceholder: "aggressive"
        });
    }

    change() {
        document.querySelector('input[name="phone"]').value = this.iti.getNumber(intlTelInputUtils.numberFormat.E164);
    }
  }
