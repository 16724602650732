import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 
    "interval", 
    "yearly", 
    "monthly", 
    "planType", 
    "badge",
    "plan" 
  ]

  connect() {
    console.log('Connected');
    this.selectYearlyStartup();
    this.planTarget.value = "cosec_startup_yearly";
  }

  interval(event) {
    this.intervalTargets.forEach(element => {
      element.classList.toggle("active");
    });

    const activeInterval = this.intervalTargets.find(
      (element) => element.classList.contains("active")
    );

    if ( activeInterval.id == "yearly" ) {
      this.showYearlyPackages();
      console.log("yearly");
    } else {
      this.showMonthlyPackages();
      console.log("monthly");
    }
  }

  select(event) {
    if (event.currentTarget.id == 'lite-yearly') {
      this.selectYearlyLite(event);
    } else if (event.currentTarget.id == 'lite-monthly') {
      this.selectMonthlyLite(event);
    } else if (event.currentTarget.id == 'startup-yearly') {
      this.selectYearlyStartup(event);
    } else if (event.currentTarget.id == 'startup-monthly') {
      this.selectMonthlyStartup(event);
    }
  }

  // selectPackage(package) {
  // }

  selectYearlyLite() {
    this.resetSelection();

    let element = document.getElementById("lite-yearly");
    element.classList.remove("not-selected");
    element.classList.add("selected");

    element.querySelector('.badge').classList.remove("opacity-0");

    this.planTarget.value = "cosec_yearly_adhoc";
  }

  selectMonthlyLite() {
    this.resetSelection();

    let element = document.getElementById("lite-monthly");
    element.classList.remove("not-selected");
    element.classList.add("selected");

    element.querySelector('.badge').classList.remove("opacity-0");

    this.planTarget.value = "cosec_monthly_adhoc";
  }

  selectYearlyStartup() {
    this.resetSelection();

    let element = document.getElementById("startup-yearly");
    element.classList.remove("not-selected");
    element.classList.add("selected");

    element.querySelector('.badge').classList.remove("opacity-0");

    this.planTarget.value = "cosec_startup_yearly";
  }

  selectMonthlyStartup() {
    this.resetSelection();

    let element = document.getElementById("startup-monthly");
    element.classList.remove("not-selected");
    element.classList.add("selected");

    element.querySelector('.badge').classList.remove("opacity-0");
    
    this.planTarget.value = "cosec_startup_monthly";
  }

  resetSelection() {
    this.planTypeTargets.forEach(element => {
      element.classList.add("not-selected");
      element.classList.remove("selected");
    });
    
    this.badgeTargets.forEach(element => {
      element.classList.add("opacity-0");
    });
  }

  showYearlyPackages() {
    this.yearlyTarget.classList.remove("hidden");
    this.monthlyTarget.classList.add("hidden");
    this.setYearlyDefault();
  }

  showMonthlyPackages() {
    this.yearlyTarget.classList.add("hidden");
    this.monthlyTarget.classList.remove("hidden");
    this.setMonthlyDefault();
  }

  setYearlyDefault() {
    this.selectYearlyStartup();
  }

  setMonthlyDefault() {
    this.selectMonthlyStartup();
  }


  // select(event) {
  //   if ( event.currentTarget.classList.contains('lite-yearly') ) {
      
  //       this.lite_yearlyTarget.classList.add("selected");
  //       this.lite_yearlyTarget.classList.remove("not-selected");
  //       this.yearlyTarget.classList.add("not-selected");
  //       this.yearlyTarget.classList.remove("selected");
  //       this.monthlyBadgeTarget.classList.remove("opacity-0");
  //       this.yearlyBadgeTarget.classList.add("opacity-0");
  //       this.planTarget.value = "cosec_standard_monthly";

  //   } else {

  //       this.startup_monthlyTarget.classList.remove("selected");
  //       this.startup_monthlyTarget.classList.add("not-selected");
  //       this.yearlyTarget.classList.remove("not-selected");
  //       this.yearlyTarget.classList.add("selected");
  //       this.monthlyBadgeTarget.classList.add("opacity-0");
  //       this.yearlyBadgeTarget.classList.remove("opacity-0");
  //       this.planTarget.value = "cosec_standard_yearly";
        
  //   }
  // }

  disconnect() {
  }
}