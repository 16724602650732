import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'


export default class extends Controller {
  static targets = [ "button" ]
  static values = {
    token: String,
    clientId: String,
    nationality: String,
    phone: String,
    nextstep: String
  }

  connect() {
    if (window.ComplyCube) {

        ComplyCube.mount({
            token: this.tokenValue,
            containerId: 'complycube-mount',
            useModal: false,
            stages: [
              'intro',
              {
                name: 'documentCapture',
                options: {
                  // crossDeviceOnly: true,
                  documentTypes: this.getDocumentTypes(),
                },
              },
              {
                name: 'faceCapture',
                options: {
                  mode: 'video'
                },
              },
              {
                name: "completion",
                options: {
                  heading: "Processing your data & running checks",
                  message: ["Do not close or refresh this screen. You will be redirected automatically. If it hangs, please do contact your account manager."]
                },
              },
            ],

            onComplete: (data) => {
              this.runChecks(data);
            },

            onModalClose: function() {
              // Handle the modal closure attempt
            },

            onError: function ({ type, message }) {
              if (type === 'token_expired') {
                // Request a new SDK token
              } else {
                // Handle other errors
                console.err(message);
              }
            }

          });
    } else {
        console.error('ComplyCube SDK is not loaded');
    }
  }

  

  getDocumentTypes() {
    if (this.nationalityValue === 'MY') {
      return {
        passport: false,
        driving_license: false,
        national_identity_card: {
            country: 'MY'
        },
        residence_permit: false,
      };
    } else {
      return {
        passport: true,
        driving_license: false,
        national_identity_card: false,
        residence_permit: false,
      };
    }
  }

  disconnect() {
  }


  async runChecks(data) {  
    // Send the request to the endpoint: verification#run_checks at route: verification/run_check

    const request = new FetchRequest(
      "post", 
      "/verification/run_checks", {
          headers: {
          "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientId: this.clientIdValue,
            documentId: data.documentCapture.documentId,
            liveVideoId: data.faceCapture.liveVideoId,
          },
        ),
      },
    );

    const response = await request.perform();

    if (response.ok) {
      const body = await response.json
      console.log(body);
      
      if (body.success) {
        window.location.href = "/verification/address";
      } else {
        window.location.href = "/verification/identity?error=check_failed";
      }
    } else {
      console.error(response);
    }

  }
}
