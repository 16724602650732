// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
//  <h4>Tasks</h4>
//  <div data-controller="nested-form">
//    <template data-target="nested-form.template">
//      <%= form.fields_for :tasks, Task.new, child_index: 'NEW_RECORD' do |task| %>
//        <%= render "task_fields", form: task %>
//      <% end %>
//    </template>
//
//    <%= form.fields_for :tasks do |task| %>
//      <%= render "task_fields", form: task %>
//    <% end %>
//
//    <div class="mb-3" data-target="nested-form.links">
//      <%= link_to "Add Task", "#", class: "btn btn-outline-primary", data: { action: "click->nested-form#add_association" } %>
//    </div>
//  </div>
//
//  # _task_fields.html.erb
//  <%= content_tag :div, class: "nested-fields", data: { new_record: form.object.new_record? } do %>
//    <div class="form-group">
//      <%= form.label :description %>
//      <%= form.text_field :description, class: 'form-control' %>
//      <small><%= link_to "Remove", "#", data: { action: "click->nested-form#remove_association" } %></small>
//    </div>
//
//    <%= form.hidden_field :_destroy %>
//  <% end %>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "links", "template", "title", "count" ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
    this.count_items()
  }

  add_association(event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)

    this.count_items();
  }

  remove_association(event) {
    event.preventDefault()

    let wrapper = event.target.closest("." + this.wrapperClass);
    var items = document.querySelectorAll('.item').length;

    console.log(items);

    if  (items > 1) {
      console.log(items);
      wrapper.remove();
    } else {
      console.log(items);
      alert("You need at least 1 item");
    }

    this.count_items();
  }

  count_items() {
    var items = document.querySelectorAll('.item').length;

    console.log(items);

    if (items === 1) {
      var count = "1"
      this.countTarget.innerHTML = count;
    } else {
      var count = items.toString()
      this.countTarget.innerHTML = count;
    }
  }
}