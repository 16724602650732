import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    console.log("MODAL CONTROLLER CONNECTED")
  }

  close(e) {
    this.hideModal();
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal()
    }
  }

  hideModal() {
    this.element.remove()
  }
}
