import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
    static targets = [ "form", "submitButton", "addShareholder", "corporateFields", "individualButton", "corporateButton", "total" ]

    connect() {
        this.total();
    }

    toggle(event) {
        if (event.currentTarget.id == 'corporate') {
            this.corporateFieldsTarget.classList.remove('hidden');

            this.corporateButtonTarget.classList.add('active');
            this.corporateButtonTarget.classList.remove('inactive');
            this.individualButtonTarget.classList.remove('active');
            this.individualButtonTarget.classList.add('inactive');

            document.getElementById("type").value = "corporate";
        } else {
            this.corporateFieldsTarget.classList.add('hidden');

            this.corporateButtonTarget.classList.remove('active');
            this.corporateButtonTarget.classList.add('inactive');
            this.individualButtonTarget.classList.add('active');
            this.individualButtonTarget.classList.remove('inactive');

            document.getElementById("type").value = "individual";

            this.formTarget.querySelector("#org_name").value = "";
        }
    }


    remove(event) {
        event.preventDefault();

        var c = confirm("Are you sure you want to remove this shareholder?");
        if (c == true) {
            let shareholder = event.target.closest(".shareholder");
            shareholder.remove();
        }
        this.total();
    }

    submit(event) {
        event.preventDefault();

        let form = event.currentTarget;

        // GET FORM INPUT VALUES
        let org_name = form.querySelector("#org_name").value;
        let full_name = form.querySelector("#full_name").value;
        let email = form.querySelector("#email").value;
        // let phone = form.querySelector("#phone").value;
        let fullphone = form.querySelector('input[name="phone"]').value;
        let type = form.querySelector("#type").value;

        var name = '';
        var icon = '';
        if (type == 'corporate') {
            name = org_name;
            icon = '<i class="fas fa-building text-gray-500"></i>';
        } else {
            name = full_name;
            icon = '<i class="fas fa-user text-gray-500"></i>';
        };

        // INSERT THE HTML INCLUDING VALUES
        let html = `<div class="flex shareholder">
                        <div data-action="click->shareholders#remove" class="w-12 min-w-min px-3 py-4 text-sm font-medium flex justify-center items-center hover:bg-gray-100 text-red-300 hover:text-red-600 cursor-pointer">
                            <i class="fas fa-trash"></i>
                        </div>
                        <div class="flex-grow px-4 md:px-6 py-4 flex items-center md:border-l md:border-solid md:border-gray-200">
                            <div class="flex items-center">
                                <div class="hidden md:block flex-shrink-0">
                                    ${icon}
                                </div>
                                <div class="md:text-sm md:ml-4">
                                    <div class="text-sm font-medium text-gray-900">
                                        ${name}
                                        <input type="hidden" name="incorporation_request[shareholders][][org_name]" value="${org_name}" />
                                        <input type="hidden" name="incorporation_request[shareholders][][full_name]" value="${full_name}" />
                                        <input type="hidden" name="incorporation_request[shareholders][][type]" value="${type}" />
                                    </div>
                                    <div class="hidden md:inline-block text-gray-500">
                                        ${email}
                                        <input type="hidden" name="incorporation_request[shareholders][][email]" value="${email}" />
                                        <input type="hidden" name="incorporation_request[shareholders][][fullphone]" value="${fullphone}" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-42 px-4 md:px-6 py-4 text-left text-sm font-medium">
                            <div>
                                <input type="number" name="incorporation_request[shareholders][][no_of_shares]" value="0" class="number w-24 text-center border-gray-200 rounded p-2" data-action="keyup->shareholders#total" />
                            </div>
                        </div>
                    </div`

        this.addShareholderTarget.insertAdjacentHTML('beforebegin', html)

        // RESET FORM VALUES AFTER CLOSING
        form.querySelector("#org_name").value = '';
        form.querySelector("#full_name").value = '';
        form.querySelector("#email").value = '';
        form.querySelector("#phone").value = '';
        form.querySelector("#type").value = 'individual';

        // Close form modal
        document.getElementById("form_modal").classList.add('hidden');
        document.getElementById("modal-background").remove();
        document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden');
        document.body.style.removeProperty('padding-right');
        document.body.style.removeProperty('top');

        this.total();
    }

    total() {
        // INPUT FIELD CAN'T HAVE 0 SHARES

        // get all elements with class `number`
        var number_fields = document.querySelectorAll(".number");

        // definite total variable
        var total = 0;

        // get total sum of number inputs
        number_fields.forEach(function(ele) {
            if (ele.value != '' ) {
                total += parseInt(ele.value);
            }
        });

        // set initial value left to distribute
        let to_distribute = 2400 - total;

        this.totalTarget.innerHTML = String(to_distribute);

        // If too many shares - disable submit
        if ( to_distribute < 0 ) {
            this.totalTarget.classList.remove('text-blue-600');
            this.totalTarget.classList.add('text-red-600');
            this.submitButtonTarget.disabled = true;
        // If shares left to distribute - disable submit
        } else if ( to_distribute > 0 ) {
            this.totalTarget.classList.remove('text-red-600');
            this.totalTarget.classList.add('text-blue-600');
            this.submitButtonTarget.disabled = true;
        // if shares distributed - activate button
        } else {
            this.totalTarget.classList.remove('text-red-600');
            this.totalTarget.classList.add('text-blue-600');
            this.submitButtonTarget.disabled = false;
        };
    }

  }
