import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect () {
    Canny('initChangelog', {
        appID: '63a1e7daf58e7f3842082076',
        position: 'bottom',
        align: 'right',
    });
  }
}
