import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "container", "folderTitle" ]
  static values = {
    folderid: String,
    token: String
  }

  initialize() {
  }

  connect() {
      if (this.tokenValue === '') {
          this.fetchFolderIdValue();
          this.fetchTokenValue();
      } else {
          this.contentExplorer = new Box.ContentExplorer();
          this.accessToken = this.tokenValue;
          this.load_box(this.folderidValue);
      }
  }

  switch_folder(event) {
    this.change_styling(event.currentTarget);
    this.contentExplorer.hide();
    this.contentExplorer.clearCache();
    this.load_box(event.currentTarget.id);
  }

  load_box(folder_id) {
    this.contentExplorer.show(folder_id, this.accessToken, {
      container: this.containerTarget
    });
    this.containerTarget.classList.replace('btn', 'bx-btn')
  }

  change_styling(target) {
    // Change folder title
    this.folderTitleTarget.innerHTML = target.getAttribute('name');

    // remove selected styling from currently selected
    var current_selected = document.getElementsByClassName("selected");
    console.log(current_selected[0]);
    current_selected[0].classList.toggle('selected');

    // add selected styling to newly selected
    target.classList.toggle('selected');
  }

  fetchFolderIdValue() {
    fetch('documents/folder_id_value')
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          let statutoryFiles = document.querySelectorAll('div[name="Statutory Files"]');
          let boardResolutions = document.querySelectorAll('div[name="Board resolutions"]');
          let letters = document.querySelectorAll('div[name="Your letters"]');
          if (statutoryFiles.length) {
            statutoryFiles[0].setAttribute('id', data.statutory.id);
          }
          if (boardResolutions.length) {
            boardResolutions[0].setAttribute('id', data.minutes.id);
          }
          if (letters.length) {
            letters[0].setAttribute('id', data.letters.id);
          }
        } else {
          document.getElementById('js-error-message').text(data.message);
        }
      });
  }

  fetchTokenValue() {
    fetch('documents/token_value')
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          this.contentExplorer = new Box.ContentExplorer();
          this.accessToken = data.downscoped_token;
          this.load_box(data.statutory.id);
        } else {
          document.getElementById('js-error-message').text(data.message);
        }
      });
  }

  disconnect() {
  }
}