import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select';

export default class extends Controller {
  static values = {
    selected: String,
    placeholder: String
  };

  connect() {
    this.slimselect = new SlimSelect({
      select: this.element,
      placeholder: this.placeholderValue,
    //   searchPlaceholder: 'Enter keyword related to your business',
      deselectLabel: '<span class="text-red-600 hover:font-bold ml-1 mt-1">✕</span>',
    });
    this.slimselect.set(this.selectedValue);
  }

  disconnect() {
    this.slimselect.destroy();
  }
}