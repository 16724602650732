import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "passport_expiry", "id_document_label", "id_document_input", "race", "raceblock" ]

  connect() {
  }

  set_nationality(event) {
     if (event.currentTarget.value != "MY" ) {
        this.passport_expiryTarget.classList.remove("hidden");
        this.id_document_labelTarget.innerHTML = "Your passport number";
        this.id_document_inputTarget.setAttribute("placeholder", "Enter your passport number");
        this.raceTarget.value = "Foreigner";
        this.raceblockTarget.classList.add("hidden");
     } else {
        this.passport_expiryTarget
        this.id_document_labelTarget.innerHTML = "Your IC (MyKad) number";
        this.id_document_inputTarget.setAttribute("placeholder", "000000-00-0000");
        this.raceTarget.value = "";
        this.raceblockTarget.classList.remove("hidden");
     };
  }
}