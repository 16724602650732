import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select';

export default class extends Controller {
  static values = {
    selected: Array
  };

  connect() {
    this.msic = new SlimSelect({
      select: this.element,
      placeholder: "Select MSIC codes",
      searchPlaceholder: 'Enter keyword related to your business',
      limit: 3,
      deselectLabel: '<span class="text-red-600 hover:font-bold ml-1 mt-1">✕</span>',
      data: require('../../../public/msic.json')
    });
    this.msic.set(this.selectedValue);
  }

  disconnect() {
    this.msic.destroy();
  }
}