import { Controller } from "@hotwired/stimulus"
import confetti from 'canvas-confetti'

export default class extends Controller {
    static targets = [ "confetti" ]
  
    connect() {
        console.log(this.confettiTarget)

        var end = Date.now() + (15 * 1000);

        // go Buckeyes!
        var colors = ['#3498db', '#f4d03f'];
        
        (function frame() {
          confetti({
            particleCount: 3,
            angle: 60,
            spread: 55,
            origin: { x: 0 }
            // colors: colors
          });
          confetti({
            particleCount: 3,
            angle: 120,
            spread: 55,
            origin: { x: 1 }
            // colors: colors
          });
        
          if (Date.now() < end) {
            requestAnimationFrame(frame);
          }
        }());

        // confetti();
    }

  } 
 
